import { FC, useContext, useState, useMemo, useEffect } from "react";
import Link from "next/link";
import { AuthProvider } from "pages";
import { MenuItemDef } from "types/app";
import { MENU_DEFINITIONS, RESOURCE_DEFINITION } from "constant";
import {
  List,
  ListItem,
  Divider,
  ListItemIcon,
  Collapse,
  ListItemText,
  withStyles,
  Tooltip,
  TextField,
  Zoom,
  IconButton,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core";
import { Close, ExpandLessSharp, ExpandMoreSharp } from "@material-ui/icons";
import AppLayoutContext from "components/context/AppLayoutContext";
import dynamic from "next/dynamic";
import { useDebounce } from "ahooks";
import cloneDeep from "lodash/cloneDeep";
import { lightTheme } from "theme";

const onClick = (menu: string) => () => {
  // // eslint-disable-next-line
  const _paq = (window as any)._paq || [];
  _paq.push(["setCustomVariable", 2, "Menu", menu, "page"]);
  _paq.push(["trackEvent", "Menu", "track", "Usage", menu]);
};

const useStyles = makeStyles(() => ({
  root: (props: any) => ({
    "&::before": {
      bottom: -4,
      display: props.open ? "block" : "none",
    },
    "&::after": {
      bottom: -4,
      display: props.open ? "block" : "none",
    },
    // width: "max-content",
    // marginLeft: 20,
  }),
}));

const StyledListItemText = withStyles({
  primary: {
    color: "#666666",
    fontSize: 15,
    whiteSpace: "nowrap",
    overflowX: "hidden",
    textOverflow: "ellipsis",
    ".Mui-selected &&": {
      color: "#121212",
    },
    // padding: "11px 0",
  },
})(ListItemText);

const StyledListItem = withStyles({
  root: {
    marginLeft: 4,
    borderLeft: "4px solid white",
  },
  selected: {
    backgroundColor: "#F7F7F7 !important",
    borderColor: "#292929 !important",
  },
})(ListItem);

const StyledListItemIcon = withStyles({
  root: {
    minWidth: 36,
  },
})(ListItemIcon);

const isMenuSelected = (def: MenuItemDef) => {
  return window.location.pathname === def.href;
};

const AppMenu: FC = () => {
  const authDetail = useContext(AuthProvider);
  const { open, hoverOpen } = useContext(AppLayoutContext);
  const classes = useStyles({ open: open || hoverOpen });
  const shouldMenuShow = (item: MenuItemDef) =>
    authDetail?.menus.includes(item.authKey ?? "") &&
    !item.summaryRoute &&
    !item.hide &&
    (!item.toggle ||
      (item.toggle && authDetail?.toggles && authDetail?.toggles[item.toggle]));

  const [value, setValue] = useState<string>("");
  const debouncedValue = useDebounce(value, { wait: 500 });

  const renderMenu = (defs: MenuItemDef[], keyword: string) => (
    <List>
      {defs
        .filter(function f(o): boolean {
          if (!keyword) return true;
          return (
            o.title.toLowerCase().includes(keyword.toLowerCase()) ||
            (!!o.children && (o.children = o.children.filter(f)).length > 0)
          );
        })
        .reduce(function cb(result, cur) {
          const res = (cur.children || []).reduce(cb, []);
          if (res.length) {
            cur.children = res;
            // push parent menu to the list
            result.push(cur);
          }
          if (shouldMenuShow(cur)) {
            result.push(cur);
          }
          return result;
        }, [] as MenuItemDef[])
        .map((def) => (
          <MenuList def={def} key={def.title} shouldOpen={!!keyword} />
        ))}
    </List>
  );

  const menus = useMemo(
    () => renderMenu(cloneDeep(MENU_DEFINITIONS), debouncedValue),
    [location.pathname, debouncedValue]
  );
  const resources = useMemo(
    () =>
      renderMenu(
        RESOURCE_DEFINITION.map((def) => ({
          ...def,
          icon: def.children ? "/menu/group.svg" : "/menu/link.svg",
          children: def.children
            ? def.children.map((x) => ({ ...x, icon: "/menu/link.svg" }))
            : undefined,
        })),
        debouncedValue
      ),
    [location.pathname, debouncedValue]
  );
  const resourcesButton = useMemo(
    () => (
      <List>
        <Tooltip title={"Resources"} enterDelay={1000} enterNextDelay={1000}>
          <StyledListItem button={true}>
            <StyledListItemIcon>
              <img src={"/menu/link.svg"} width={16} height={16} />
            </StyledListItemIcon>
            <StyledListItemText primary={"Resources"} />
          </StyledListItem>
        </Tooltip>
      </List>
    ),
    []
  );

  return (
    <ThemeProvider theme={lightTheme}>
      <nav style={{ overflowX: "hidden" }}>
        <div style={{ padding: "0 20px" }}>
          <TextField
            size={"medium"}
            fullWidth={true}
            // variant={"outlined"}
            value={value}
            InputProps={{
              className: classes.root,
              startAdornment: (
                <img
                  style={{
                    marginLeft: 4,
                    // background: 'no-repeat url("/menu/search.svg")',
                    // width: 28,
                    // height: 18,
                    objectFit: "cover",
                    maxWidth: 18,
                    height: 18,
                  }}
                  src={"/menu/search.svg"}
                />
              ),
              endAdornment: (
                <Zoom in={!!debouncedValue}>
                  <IconButton size={"small"} onClick={() => setValue("")}>
                    <Close
                      style={{
                        filter: "opacity(0.8)",
                        fontSize: "1.1rem",
                      }}
                    />
                  </IconButton>
                </Zoom>
              ),
            }}
            inputProps={{
              style: {
                fontSize: 16,
                lineHeight: "18px",
                paddingLeft: 16,
              },
            }}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
        {menus}
        <Divider style={{ margin: "12px 0" }} />
        {open || hoverOpen ? (
          <p
            style={{
              color: "#666666",
              fontSize: 14,
              lineHeight: "13px",
              fontWeight: 600,
              marginLeft: 24,
              marginTop: 24,
            }}
          >
            RESOURCES
          </p>
        ) : null}
        {open || hoverOpen ? resources : resourcesButton}
      </nav>
    </ThemeProvider>
  );
};

const MenuList: FC<{ def: MenuItemDef; shouldOpen?: boolean }> = ({
  def,
  shouldOpen,
}) => {
  return def.children ? (
    <ExpandableList def={def} shouldOpen={shouldOpen} />
  ) : (
    <Link href={def.href as string} passHref={true}>
      <Tooltip title={def.title} enterDelay={1000} enterNextDelay={1000}>
        <StyledListItem
          alignItems={"center"}
          //@ts-ignore
          component="a"
          button={true}
          onClick={onClick(def.title)}
          selected={isMenuSelected(def)}
          target={def.external ? "_blank" : "_self"}
        >
          {def.icon ? (
            <StyledListItemIcon>
              {<img width={16} height={16} src={def.icon} />}
            </StyledListItemIcon>
          ) : null}
          <StyledListItemText primary={def.title} />
        </StyledListItem>
      </Tooltip>
    </Link>
  );
};

const ExpandableList: FC<{ def: MenuItemDef; shouldOpen?: boolean }> = ({
  def,
  shouldOpen,
}) => {
  const [open, setOpen] = useState(false);
  const { open: drawerOpen, hoverOpen } = useContext(AppLayoutContext);
  useEffect(() => {
    if (!drawerOpen && !hoverOpen && open) {
      setOpen(false);
    }
    if (
      (drawerOpen || hoverOpen) &&
      def.children?.filter((x) => isMenuSelected(x))?.length
    ) {
      setOpen(true);
    }
  }, [drawerOpen, hoverOpen]);

  useEffect(() => {
    setOpen(!!shouldOpen);
  }, [shouldOpen]);

  return (
    <>
      <Tooltip
        arrow={true}
        title={def.title}
        enterDelay={1000}
        enterNextDelay={1000}
      >
        <StyledListItem
          alignItems={"center"}
          button={true}
          onClick={() => {
            setOpen((prv) => !prv);
          }}
          selected={def.children!.filter((y) => isMenuSelected(y)).length > 0}
        >
          {def.icon ? (
            <StyledListItemIcon>
              <img width={16} height={16} src={def.icon} />
            </StyledListItemIcon>
          ) : null}
          <StyledListItemText primary={def.title} />
          {open ? (
            <ExpandLessSharp style={{ color: "#898989" }} />
          ) : (
            <ExpandMoreSharp style={{ color: "#898989" }} />
          )}
        </StyledListItem>
      </Tooltip>
      <Collapse in={open}>
        <List disablePadding={true}>
          {def.children!.map((x) => (
            <Link href={x.href as string} passHref={true} key={x.title}>
              <Tooltip title={x.title} enterDelay={1000} enterNextDelay={1000}>
                <StyledListItem
                  button={true}
                  //@ts-ignore
                  component={"a"}
                  onClick={onClick(x.title)}
                  style={{ paddingLeft: 52 }}
                  selected={isMenuSelected(x)}
                  target={x.external ? "_blank" : "_self"}
                >
                  <StyledListItemText primary={x.title} />
                </StyledListItem>
              </Tooltip>
            </Link>
          ))}
        </List>
        ;
      </Collapse>
    </>
  );
};

export default dynamic(() => Promise.resolve(AppMenu), { ssr: false });
