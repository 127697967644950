// for portfolio banner flag calc
export enum FlagEnum {
  BigFish = 17,
  LittleFish = 16,
  DedicatedInstance = 20,
}

export const isFlag = (val: number, flag: FlagEnum): boolean => {
  const mask = 1 << flag;
  return (val & mask) !== 0;
};

export const getFlagCode = (flag: FlagEnum): number => {
  return Math.pow(2, flag);
};

export const isEmptyObject = (obj: any): boolean => {
  return (
    typeof obj === "object" && obj !== null && Object.keys(obj).length === 0
  );
};
