import { MenuItemDef } from "types/app";

export const IS_DEV = process.env.NODE_ENV !== "production";
export const IS_SERVER = typeof window === "undefined";
export const PATH_PREFIX = "/spng";
export const EMBED = !IS_SERVER && window.self !== window.top;
export enum FEATURE_TOGGLES {
  MOCK_USER_LOGIN = "WXCSENG_MOCK_USER_LOGIN",
  NEW_HOME_CONFIGURATION = "success-portal-new-home-dashboard-configuration",
  WAP_CHART = "success-portal-wap-chart",
  COMMENT_SUMMARIZE = "viewCommentSummarize",
  // SUCCESS_PLAN_REVAMP = "success-plan-revamp",
}
export enum FEATURE_KEY {
  PORTFOLIO_ACCOUNTS_DOWNLOAD = "portfolio-accountsDownload",
  PORTFOLIO_CONTACTS_DOWNLOAD = "portfolio-contactsDownload",
  PORTFOLIO_PERSONAL_TAG = "portfolio-editPersonalTag",
  PORTFOLIO_MANAGER_TAG = "portfolio-editManagerTag",
  PORTFOLIO_SEARCH_ONLINE_ACCOUNT = "portfolio-searchOnlineAccount",
  PORTFOLIO_RENEW_SEARCH_BAR = "portfolio-renewSearchBar",
  PORTFOLIO_TOP_N_ACCOUNTS_FILTER = "portfolio-quickFilterTopN",
  PORTFOLIO_EDIT_TAE = "portfolio-editTae",
  PORTFOLIO_AROFFPC_ALERT = "portfolio-arOffPcAlert",
  ALERT = "alert",
  APP_TOGGLE_CONTROL = "toggleControl",
  PORTFOLIO_ACCOUNT_TEAM = "portfolio-viewAccountContact",
  VIEW_MEETING_USAGE_LEGACY = "meetingUsageViewLegacy",
  VIEW_MEETING_USAGE_LEGACY_MONTH_SELECTION = "meetingUsageViewLegacyMonthSelection",
  VIEW_TEAMS_USAGE_BENCHMARK = "teamsUsageViewBenchmark",
  VIEW_PARTNER_EXPAND_PLAN = "viewPartnerExpandPlan",
  VIEW_CISCO_EXPAND_PLAN = "viewCiscoExpandPlan",
  VIEW_SUBSCRIPTION_DETAIL = "viewSubscriptionDetail",
  VIEW_COMPETITOR_DETAIL = "viewCompetitorDetail",
  VIEW_SUMMARY_ARPC_ALERT = "viewSummaryArOffPcAlert",
  VIEW_SUMMARY_CONTACT_VIEW_ACTIVITY_LEVEL = "viewSummaryContactActivityLevel",
  VIEW_SUMMARY_CONTACT_OPTOUT = "viewSummaryContactOptOut",
  EDIT_SUMMARY_CONTACT_ACTIVITYLEVEL = "editSummaryContactActivityLevel",
  EDIT_SUMMARY_CONTACT_OPTOUT = "editSummaryContactOptOut",
  VIEW_SUMMARY_CONTACT_ACTION_COLUMN = "viewSummaryContactActionColumn",
  VIEW_NOTE = "viewNote",
  EDIT_NOTE = "editNote",
  VIEW_QVR = "viewQvr",
  EDIT_QVR = "editQvr",
  VIEW_COMPETITOR = "viewCompetitor",
  VIEW_MEETINGS_NPS_SCORE = "viewMeetingsNPSScore",
  EDIT_COMPETITOR = "editCompetitor",
  VIEW_COLLAB_MAP = "viewCollabMap",
  UPLOAD_COLLAB_MAP = "uploadCollabMap",
  UPDATE_SUBSCRIPTION_HEALTH = "updateSubscriptionHealth",
  VIEW_SUMMARY_CONTACT = "viewSummaryContact",
  UPDATE_CISCO_EXPAND_PLAN = "updateCiscoExpandPlan",
  UPDATE_PARTNER_EXPAND_PLAN = "updatePartnerExpandPlan",
  VIEW_CONCIERGE = "viewConcierge",
  UPDATE_CONCIERGE = "updateConcierge",
  PORTFOLIO_EDIT_CALLING_PSM = "portfolio-editCallingPsm",
  ENTERPRISE_ACCOUNTS_DOWNLOAD = "enterprise-accountsDownload",
  HEALTH_ACCOUNTS_DOWNLOAD = "health-accountDownload",
  BENCHMARKING_ACCOUNTS_DOWNLOAD = "benchmarking-accountDownload",
  MEETING_USAGE_DOWNLOAD = "meetingUsageDownload",
  MESSAGING_USAGE_DOWNLOAD = "messagingUsageDownload",
  CALLING_USAGE_DOWNLOAD = "callingUsageDownload",
  HOST_USAGE_DOWNLOAD = "hostUsageDownload",
  OVERAGE_USAGE_DOWNLOAD = "overageUsageDownload",
  TRANSACTION_HISTORY_DOWNLOAD = "transactionHistoryDownload",
  TECH_SUPPORT_DOWNLOAD = "techSupportDownload",
  VIEW_ONEVIEW_HEALTH_INSIGHTS = "viewOneViewHealthInsights",
  VIEW_OTHER_ACCOUNTS = "viewOtherAccounts",
  VIEW_ACTIVITY = "viewActivity",
  CREATE_ACTIVITY = "createActivity",
  VIEW_ASSIGN_TO_MY_TEAM = "viewAssignToMyTeam",
  ACTIVITY_DOWNLOAD = "activityDownload",
  UPDATE_ACCOUNT_COVERAGE = "editAccountCoverage",
  EDIT_SYSTEM_PLAYBOOK = "editSystemPlaybook",
  APPROVE_SYSTEM_PLAYBOOK = "approveSystemPlaybook",
  EDIT_ANCHOR_PRODUCTS = "editAnchorProducts",
  PORTFOLIO_ASSIGN_MANAGER_TAG = "portfolio-assignManagerTag",
  EDIT_EXEC_FOCUS = "editExecFocus",
  VIEW_COMMENT_SUMMARIZE = "viewCommentSummarize",
  EDIT_CCE_ON_PREM = "editCCEOnPrem",
  EDIT_PARTNER_SUCCESS = "editCallingSuccess",
  EDIT_EXPAND_PLAN_REVAMP = "editExpandPlanRevamp",
  EDIT_SUCCESS_PLAN = "editSuccessPlan",
}
export const MENU_DEFINITIONS: MenuItemDef[] = [
  { title: "Error", href: "/spng/error", hide: true },
  { title: "Login", href: "/spng/login", hide: true },
  { title: "Middle", href: "/spng/middle", hide: true },
  {
    title: "Home",
    authKey: "home",
    href: "/spng/home",
    icon: "/menu/home.svg",
  },
  {
    title: "Home Setting",
    href: "/spng/home/setting",
    toggle: FEATURE_TOGGLES.NEW_HOME_CONFIGURATION,
    authKey: "userAdminTool",
    hide: true,
  },
  {
    title: "Portfolio",
    authKey: "portfolio",
    href: "/spng/portfolio",
    icon: "/menu/portfolio.svg",
    enableSearch: true,
  },
  {
    title: "Activity",
    href: "#",
    icon: "/menu/activity.svg",
    children: [
      {
        title: "My Activity",
        authKey: "myActivity",
        href: "/spng/activity",
      },
      {
        title: "My Task",
        authKey: "myActivity",
        href: "/spng/task",
      },
      {
        title: "Template",
        authKey: "playbook",
        href: "/spng/playbook",
      },
      {
        title: "Template Create",
        authKey: "playbook",
        href: "/spng/playbook/create",
        hide: true,
      },
    ],
  },
  {
    title: "Health",
    children: [
      {
        title: "Enterprise Setup Dashboard",
        authKey: "enterpriseSetupDashboard",
        href: "/spng/dashboard/enterprise",
        enableSearch: true,
        hideIncludeInactive: true,
      },
      {
        title: "Renewals Assessment Management",
        authKey: "renewalsManagementDashboard",
        href: "/spng/dashboard/renewals",
        enableSearch: true,
        hideIncludeInactive: true,
      },
      {
        title: "Top Accounts Executive Dashboard",
        authKey: "executiveHealthDashboard",
        href: "/spng/dashboard/executive",
        enableSearch: true,
        hideIncludeInactive: true,
      },
      {
        title: "Benchmarking",
        authKey: "benchmarking",
        href: "/spng/dashboard/benchmarking",
        enableSearch: true,
        hideIncludeInactive: true,
      },
    ],
    href: "#",
    icon: "/menu/health.svg",
  },
  {
    title: "Emails",
    href: "#",
    icon: "/menu/email.svg",
    children: [
      {
        title: "Resource",
        authKey: "emailCampaigns",
        href: "/spng/email/campaign",
      },
      {
        title: "Sent Emails",
        authKey: "sentEmails",
        href: "/spng/email/sent",
        enableSearch: true,
        hideIncludeInactive: true,
      },
      {
        title: "Compose Email",
        authKey: "composeEmails",
        href: "/spng/email/compose",
        hide: true,
      },
      {
        title: "Email Detail",
        authKey: "sentEmails",
        href: "/spng/email/detail",
        hide: true,
      },
      {
        title: "Email Report",
        authKey: "sentEmails",
        href: "/spng/email/report",
        hide: true,
      },
    ],
  },
  {
    title: "Details",
    authKey: "accountSummary",
    href: "/spng/summary",
    summaryRoute: true,
  },
  {
    title: "Success Plan",
    authKey: "expandPlanRevamp",
    href: "/spng/summary/expand-plan-revamp",
    // toggle: FEATURE_TOGGLES.SUCCESS_PLAN_REVAMP,
    summaryRoute: true,
    includeDownload: true,
  },
  {
    title: "Renewal Assessment",
    authKey: "renewalsManagementDashboard",
    href: "#",
    summaryRoute: true,
    includeAlert: true,
  },
  {
    title: "Activity",
    authKey: "activity",
    href: "/spng/summary/activity",
    summaryRoute: true,
  },
  {
    title: "Usage",
    summaryRoute: true,
    href: "#",
    children: [
      {
        title: "Meeting",
        authKey: "meetingUsage",
        href: "/spng/summary/usage/meeting",
        summaryRoute: true,
        subSummaryRoute: true,
      },
      {
        title: "Messaging",
        authKey: "teamsUsage",
        href: "/spng/summary/usage/teams",
        summaryRoute: true,
        confidential: true,
        subSummaryRoute: true,
      },
      {
        title: "Calling",
        authKey: "calling",
        href: "/spng/summary/usage/calling",
        summaryRoute: true,
        confidential: true,
        subSummaryRoute: true,
      },
      {
        title: "Meeting Host",
        authKey: "hostUsage",
        href: "/spng/summary/usage/host",
        summaryRoute: true,
        subSummaryRoute: true,
      },
      {
        title: "Overage",
        authKey: "overageUsage",
        href: "/spng/summary/usage/overage",
        summaryRoute: true,
        subSummaryRoute: true,
      },
    ],
  },
  {
    title: "Transaction History",
    authKey: "transactionHistory",
    href: "/spng/summary/transaction-history",
    summaryRoute: true,
  },
  {
    title: "Technical Support Information",
    authKey: "technicalSupportInformation",
    href: "/spng/summary/technical-support-information",
    summaryRoute: true,
  },
  {
    title: "Activity Detail",
    authKey: "activity",
    href: "/spng/activity/detail",
    hide: true,
  },
  {
    title: "Collab Success Plan",
    authKey: "accountSummary",
    href: "/spng/summary/expand-plan",
    hide: true,
  },
  {
    title: "Support",
    icon: "/menu/support.svg",
    children: [
      {
        title: "Open A Case",
        authKey: "openACase",
        href:
          "https://cisco.service-now.com/sp?id=sc_cat_item&sys_id=a9860b89dbd9a640cb5772fc0f96191d&&u_business_service=36a0f2a5db95c34063339c26db961943",
        external: true,
      },
      {
        title: "Open a case for User Admin Portal",
        authKey: "openAcaseForUserAdminPortal",
        href:
          "https://cisco.service-now.com/sp?id=sc_cat_item&sys_id=a9860b89dbd9a640cb5772fc0f96191d&u_business_service=aa1c09c0db891708a677fe8b0c961904",
        external: true,
      },
    ],
    href: "#",
  },
  {
    title: "Legecy Release Notes",
    authKey: "releaseNotes",
    href: "/spng/release/archived",
    hide: true,
  },
  {
    title: "Release Notes",
    authKey: "release",
    href: "/spng/release",
    icon: "/menu/release.svg",
  },
  // {
  //   title: "Manager Reports",
  //   children: [
  //     // {
  //     //   title: "Notes Tracker",
  //     //   authKey: "notesTracker",
  //     //   href: "/main/notestracker",
  //     // },
  //     {
  //       title: "Top Accounts",
  //       authKey: "topAccounts",
  //       href: "/spng/dashboard/top-accounts",
  //       toggle: FEATURE_TOGGLES.TOP_ACCOUNTS_DASHBOARD,
  //     },
  //   ],
  //   href: "#",
  // },
  {
    title: "Manager Tools",
    icon: "/menu/manager.svg",
    children: [
      {
        title: "User Admin Tool",
        authKey: "userAdminTool",
        href: "/main/userAdmin",
      },
      {
        title: "Account Assignment Tool",
        authKey: "accountAssignmentTool",
        href: "/main/assignmentTool",
      },
    ],
    href: "#",
  },
];
export const RESOURCE_DEFINITION: MenuItemDef[] = [
  {
    title: "Adoption",
    href: "#",
    children: [
      {
        title: "Essentials Adoption Resources",
        authKey: "adoption",
        href: "https://essentials.webex.com/adoption-toolkits.html",
        external: true,
      },
      {
        title: "Lifecycle PowerBI",
        authKey: "webexSuitePowerBI",
        href:
          "https://app.powerbi.com/groups/me/apps/4b9b445f-79c0-4b58-b04b-33a1aae5f68d/reports/783ed656-b961-4b01-9b23-d9e3559c2542/ReportSectionba929d058ee04a849eb4?ctid=5ae1af62-9505-4097-a69a-c1553ef7840e&experience=power-bi&clientSideAuth=0",
        external: true,
      },
      {
        title: "Device Usage Insights",
        authKey: "deviceUsageInsights",
        href:
          "https://tableau.cisco.com/#/site/CCTG/views/OSS2_0/OSS-PHONES?:iid=1",
        external: true,
      },
    ],
  },
  {
    title: "Collab Customer Success (CS)",
    href: "#",
    children: [
      {
        title: "GoLearn.webex.com",
        authKey: "CSLearningMapeBook",
        href: "https://golearn.webex.com",
        external: true,
      },
      {
        title: "CS Process Library",
        authKey: "CSProcessLibrary",
        href: "https://cisco.sharepoint.com/sites/CSProcessLibrary",
        external: true,
      },
      {
        title: "CS Sharepoint",
        authKey: "collaborationCSSharepoint",
        href: "https://cisco.sharepoint.com/sites/GlobalCollabCustomer",
        external: true,
      },
      {
        title: "Enablement Sharepoint Site",
        authKey: "enablementSharepointSite",
        href: "https://cisco.sharepoint.com/sites/CSMandPartnerEnablementSite",
        external: true,
      },
    ],
  },
  {
    title: "Webex Resources",
    href: "#",
    children: [
      {
        title: "Webex IT Admin Essentials",
        authKey: "webexITAdminEssentials",
        href: "https://brandfolder.com/portals/webex-admin",
        external: true,
      },
      {
        title: "Webex User Essentials",
        authKey: "webexUserEssentials",
        href: "https://brandfolder.com/portals/users",
        external: true,
      },
    ],
  },
  {
    title: "Digital Engagement Insights",
    authKey: "DXEcampaignDashboard",
    href:
      "https://app.powerbi.com/groups/me/apps/4b9b445f-79c0-4b58-b04b-33a1aae5f68d/reports/94b34b60-4f06-41b6-a4f8-a8cfa5458f09/ReportSection",
    external: true,
  },
  {
    title: "KnowledgeBase",
    authKey: "knowledgeBase",
    href: "https://csone.my.salesforce.com/",
    external: true,
  },
  {
    title: "Opt Customer Out of Campaigns",
    authKey: "optCustomerOutOfCampaigns",
    href: "https://app.smartsheet.com/b/form/dabfa1a390c64b0daabb4b72fd8f33a7",
    external: true,
  },
  {
    title: "Other Tools & Portals",
    href: "#",
    children: [
      {
        title: "CSM and Extended Account team Lookup Tool",
        authKey: "lookupTool",
        href: "/tools/lookup",
        external: true,
      },
      {
        title: "Collaboration Help Portal",
        authKey: "collaborationHelpPortal",
        href: "https://collaborationhelp.cisco.com/?language=en-us",
        external: true,
      },
      {
        title: "Control Hub",
        authKey: "controlHub",
        href: "https://admin.webex.com",
        external: true,
      },
      {
        title: "OneView Portal",
        authKey: "oneViewHealthReport",
        href: "https://oneview.webex.com/report/healthMonitor",
        external: true,
      },
      {
        title: "Site Admin",
        authKey: "portfolio",
        href: "https://gfc.webex.com/#/login",
        external: true,
      },
      {
        title: "SSW",
        authKey: "ssw",
        href: "https://ssw.cisco.com/software/ssw/platform/home",
        external: true,
      },
      {
        title: "WRAP Sales Portal",
        authKey: "WRAPSalesPortal",
        href: "https://ib-collab.cisco.com/app/",
        external: true,
      },
    ],
  },
];
// dataservice keyword
export const KEYWORD_MAPPING = {
  contains: "co",
  equals: "eq",
  in: "in",
  notIn: "ni",
  greaterThan: "gt",
  lessThan: "lt",
  lessThanOrEqual: "le",
  greaterThanOrEqual: "ge",
  between: "",
  // for collapmap filter yes without date
  notEquals: "ne",
  // to filter invoice ARR/MRR equal BLANK
  isNetNew: "",
};

export const KEYWORD_SYMBOL_MAPPING = {
  contains: "=",
  equals: "=",
  in: "=",
  notIn: "!=",
  greaterThan: ">",
  lessThan: "<",
  lessThanOrEqual: "<=",
  greaterThanOrEqual: ">=",
  between: "between",
  isNetNew: "is",
  notEquals: "!=",
};

export const FILTER_KEY_MAPPING = {
  sitesPrimaryCluster: "clusterName",
  sitesPrimaryWebexVersion: "desktopAppVersion",
  sitesPrimaryWSMPFlag: "wsmpFlag",
  sitesPrimaryWSMPDate: "wsmpDate",
  sitesPrimaryAiEnabledFlag: "aiEnabledFlag",
  sitesPrimaryAiEnabledDate: "aiEnabledDate",
  expandPlanCreatedDate: "expandPlanExists",
  callingEPDate: "callingEPExists",
  ccEPDate: "ccEPExists",
  ccseEPDate: "ccseEPExists",
  ciscoManagerName: "ciscoCsmManager",
  partnerManagerName: "psmManager",
  partnerAccountName: "partnerFilter",
  //#TO BE REMOVED# workaround to add this column since backend do not have the column
  accountSK: "renewalTerm",
  partnerExpandPlanCreatedDate: "partnerExpandPlanExists",
  renewalQuarter: "fiscalRenewalMonth",
  arOffPCCount: "arOffPC",
  callingPsmName: "callingPsmId",
  callingPsmManagerName: "callingPsmManagerId",
  callingEpsmName: "callingEpsmId",
  callingEpsmManagerName: "callingEpsmManagerId",
  externalAlertColumn: "alertSubType",
  dsmName: "dsmId",
  attributes: "playbookApplied",
  ccCsmName: "ccCsmId",
  cxCseName: "cxCseId",
  ccseJiraId: "jiraId",
  ccseJiraName: "jiraName",
  ccseJiraDate: "jiraDate",
  ccseJiraStatus: "jiraStatus",
};

export const ACTIVITY_KEY_MAPPING = {
  // assigneeName: "assigneeRole",
  creatorName: "creatorRole",
};

export const PlAYBOOK_KEY_MAPPING = {};

export const PRIMARY_ROLES = ["Business", "Billing", "Site Admin"];

export const DASHBOARD_ENTERPRISE_KEY_MAPPING = {
  ciscoCsmName: "ciscoCsmId",
  partnerCsmName: "partnerCsmId",
  ciscoManagerName: "ciscoManagerId",
  partnerManagerName: "partnerManagerId",
  partnerAccountName: "partnerFilter",
  "meetings-desktopAppVersion": "desktopAppVersion",
  countryName: "countryCode",
  callingPsmName: "callingPsmId",
  callingPsmManagerName: "callingPsmManagerId",
  callingEpsmName: "callingEpsmId",
  callingEpsmManagerName: "callingEpsmManagerId",
  "foundationalSetup-latestVersionWAU": "webexAppVersionLatest",
  "foundationalSetup-oldestVersionWAU": "webexAppVersionOldest",
  ccCsmName: "ccCsmId",
  cxCseName: "cxCseId",
};
export const DASHBOARD_FORECAST_KEY_MAPPING = {
  ciscoCsmName: "ciscoCsmId",
  partnerCsmName: "partnerCsmId",
  ciscoManagerName: "ciscoManagerId",
  partnerManagerName: "partnerManagerId",
  fiscalMonthName: "fiscalMonthId",
  fiscalRenewalMonth: "fiscalRenewalMonth",
  callingPsmName: "callingPsmId",
  callingPsmManagerName: "callingPsmManagerId",
  callingEpsmName: "callingEpsmId",
  callingEpsmManagerName: "callingEpsmManagerId",
  ccCsmName: "ccCsmId",
  cxCseName: "cxCseId",
};

export const DASHBOARD_ENTERPRISE_GROUP_HEADER_KEY_MAPPING = {
  foundationalSetup: "PLATFORM IDENTITY",
  meetings: "MEETINGS",
  hyb: "HYBRID SERVICES",
  endCustomer: "END CUSTOMER",
  other: "OTHER",
};

export const BENCHMARKING_GROUP_HEADER_KEY_MAPPING = {
  accountSummary: "Account Summary",
  accountSummaryDetails: "Account Summary Details",
  health: "Health",
  usage: "Usage",
  adoption: "Adoption (% of the Total Meetings)",
  adoptionPer: "Adoption (Meetings per Active Host)",
  meetings: "Meetings Utilization",
};

export const SORT_KEY_MAPPING = {};

export const DASHBOARD_RENEWALS_SORT_KEY_MAPPING = {
  fiscalMonthName: "fiscalMonthId",
  forecastingNote: "forecastNoteExist",
  epLastModifiedTime: "epModifiedDate",
  ciscoCsmName: "ciscoCsmId",
  partnerCsmName: "partnerCsmId",
  ciscoManagerName: "ciscoManagerId",
  partnerManagerName: "partnerManagerId",
};

export const DASHBOARD_BENCHMARKING_SORT_KEY_MAPPING = {};

export const DASHBOARD_BENCHMARKING_FILTER_KEY_MAPPING = {
  "accountSummaryDetails-partnerAccountName": "partnerFilter",
  "accountSummaryDetails-callingPsmName": "callingPsmId",
  "accountSummaryDetails-callingPsmManagerName": "callingPsmManagerId",
  "accountSummaryDetails-callingEpsmName": "callingEpsmId",
  "accountSummaryDetails-callingEpsmManagerName": "callingEpsmManagerId",
  "accountSummaryDetails-ciscoManagerName": "ciscoManagerId",
  "accountSummaryDetails-partnerManagerName": "partnerManagerId",
  "accountSummaryDetails-ciscoCsmName": "ciscoCsmId",
  "accountSummaryDetails-partnerCsmName": "partnerCsmId",
  "accountSummaryDetails-psmName": "psmId",
  "accountSummaryDetails-ccCsmName": "ccCsmId",
  "accountSummaryDetails-cxCseName": "cxCseId",
};

export const FILTER_TITLE_MAPPING = {
  "Cluster (Main Site)": "Cluster",
  "Desktop App Version (Main Site)": "Desktop App Version",
};

export const PAGE_SIZE = 100;
export const ALERT_PAGE_SIZE = 50;
export const ROW_HEADER_HEIGHT = 46;
export const ROW_HEIGHT = 46;
export const DOWNLOAD_PAGE_SIZE = process.env.DOWNLOAD_PAGE_SIZE
  ? parseInt(process.env.DOWNLOAD_PAGE_SIZE)
  : 2000;

export const CONTACT_MAPPING = {
  accountId: "Account ID",
  accountName: "Account Name",
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
  type: "Role",
  phoneNumber: "Phone Number",
  jobFunction: "Title",
  department: "Department",
};

export enum ROLE {
  DEV_ROLE = "DEV",
  MANAGER_ROLE = "MANAGER",
  CCSE_MANAGER_ROLE = "CCSE-MANAGER",
  CSM_ROLE = "CSM",
  CISCO_WIDE_ROLE = "CISCO-WIDE",
  CISCO_WIDE_SALES_ROLE = "CISCO-WIDE-SALES",
  PARTNER_ROLE = "PARTNER",
  CISCO_NON_CSM_ROLE = "CISCO-NON-CSM",
  CCSE_ROLE = "CCSE",
  SSI_ROLE = "SSI",
  CALLING_ROLE = "CALLING",
  CALLING_MANAGER_ROLE = "CALLING-MANAGER",
  COLLAB_SALES_ROLE = "COLLAB-SALES",
  DSM_ROLE = "DSM",
  CX_CSE_ROLE = "CX-CSE",
  CX_CSE_MANAGER_ROLE = "CX-CSE-MANAGER",
  CC_CSM_ROLE = "CC-CSM",
  CC_CSM_MANAGER_ROLE = "CC-CSM-MANAGER",
  EXEC_ADMIN_ROLE = "EXEC_ADMIN",
}

// number do not extend 5000
export const ROLE_PRIORITY: { [key in ROLE]: number } = {
  [ROLE.DEV_ROLE]: 0,
  [ROLE.PARTNER_ROLE]: 1,
  [ROLE.MANAGER_ROLE]: 50,
  [ROLE.CSM_ROLE]: 51,
  [ROLE.CCSE_MANAGER_ROLE]: 100,
  [ROLE.CALLING_MANAGER_ROLE]: 101,
  [ROLE.CALLING_ROLE]: 102,
  [ROLE.CISCO_NON_CSM_ROLE]: 400,
  [ROLE.CCSE_ROLE]: 500,
  [ROLE.SSI_ROLE]: 600,
  [ROLE.COLLAB_SALES_ROLE]: 700,
  [ROLE.DSM_ROLE]: 701,
  [ROLE.CISCO_WIDE_SALES_ROLE]: 800,
  [ROLE.CX_CSE_MANAGER_ROLE]: 900,
  [ROLE.CX_CSE_ROLE]: 1000,
  [ROLE.CC_CSM_MANAGER_ROLE]: 1100,
  [ROLE.CC_CSM_ROLE]: 1200,
  [ROLE.CISCO_WIDE_ROLE]: 3000,
  [ROLE.EXEC_ADMIN_ROLE]: 3100,
};

export const DEFAULT_MEETING_USAGE_SELECTION = [
  { name: "6m", value: "6" },
  { name: "13m", value: "13" },
  { name: "18m", value: "18" },
  { name: "24m", value: "24" },
  // { name: "36m", value: "36" },
];

export const DEFAULT_LEGACY_MEETING_USAGE_SELECTION = [
  { name: "6m", value: "6" },
  { name: "13m", value: "13" },
  { name: "18m", value: "18" },
  { name: "24m", value: "24" },
  { name: "36m", value: "36" },
];

export const DEFAULT_TEAMS_USAGE_SELECTION = [
  { name: "6m", value: "6" },
  { name: "12m", value: "12" },
  { name: "18m", value: "18" },
  { name: "24m", value: "24" },
  { name: "36m", value: "36" },
];

export const DEFAULT_CALLING_USAGE_SELECTION = [
  { name: "6m", value: "6" },
  { name: "13m", value: "13" },
  // { name: "18m", value: "18" },
  // { name: "24m", value: "24" },
  // { name: "36m", value: "36" },
];

export const MEETING_CHART_LIST = [
  "Total Meetings",
  "Total Video Meetings",
  "Total Meeting Minutes",
  "Total People Minutes",
  "Total Participants",
  "Active Hosts - Monthly",
  "Active Hosts - Weekly",
  "Active Hosts - Daily",
  "Participant Join Method",
  "Participant Joins by Region",
  "Total Audio Minutes",
  "Meetings Recorded",
  "Meetings with Screen Sharing",
  "Webex Assistant",
  "Breakout Room",
  "Webex App - Weekly",
] as const;

export type MeetingChartType = typeof MEETING_CHART_LIST[number];
export type MeetingChartListViewItem =
  | Omit<
      MeetingChartType,
      | "Active Hosts - Monthly"
      | "Active Hosts - Weekly"
      | "Active Hosts - Daily"
      | "Webex App - Weekly"
    >
  | "Active Hosts"
  | "Webex App";

export const LEGACY_MEETING_CHART_LIST = [
  "Host Utilization",
  "Host Usage Summary",
  "Number of Meetings",
  "People Minutes",
  "Meeting Size Distribution",
  "Meeting Duration Distribution",
  "Source of Webex Meeting Attendance Distribution",
  "Distribution of Meetings Attended by Mobile Device",
  "Meetings Audio Minutes",
  "Webex Video Minutes",
  "Video Meetings",
  "Meeting Sharing",
  "Productivity Tool",
  // "NBR Recordings and Playback",
  // "NBR Storage Size",
  "Collaboration Meeting Room",
  "Personal Room",
  "Virtual Background",
  "Noise Cancelling",
] as const;

export type LegacyMeetingChartType = typeof LEGACY_MEETING_CHART_LIST[number];

export type LEGACY_MEETING_CHART_LIST_VIEW_ITEM =
  | Omit<
      LegacyMeetingChartType,
      | "Meeting Size Distribution"
      | "Meeting Duration Distribution"
      | "Source of Webex Meeting Attendance Distribution"
      | "Distribution of Meetings Attended by Mobile Device"
    >
  | "Pie Charts";

export const TEAM_CHART_TYPE = [
  "Monthly Active Users",
  "Weekly Active Users",
  "Daily Active Users",
  // "Number of Messages Read",
  "Number of Messages Sent",
  "Number of Files Shared",
  "Number of Calls Placed",
] as const;

export const CALLING_CHART_TYPE = [
  "Total Calls",
  "Total Call Minutes",
  "Total MAU",
  // "Total Call Legs by Platform - Monthly",
  // "Total Call Legs by Platform - Weekly",
  // "Total Call Legs by Platform - Daily",
  // "Total Call Minutes by Platform - Monthly",
  // "Total Call Minutes by Platform - Weekly",
  // "Total Call Minutes by Platform - Daily",
] as const;

export const OLD_CALLING_CHART_TYPE = ["Number of Calls"] as const;

export const WEBEX_SITE_EXTENSION = ".webex.com";
export const ZOOM_COMPETITOR_SITE_EXTENSION = ".zoom.us";
export const MICROSOFT_COMPETITOR_SITE_EXTENSION = ".skype.com";
export const BLUEJEANS_COMPETITOR_SITE_EXTENSION = ".bluejeans.net";
export const LOGMEIN_COMPETITOR_SITE_EXTENSION = ".logmein.net";

export const GLOBAL_SEARCH_RECENT_KEY = "GLOBAL_SEARCH_RECENT";

export const PORTFOLIO_LAST_SEARCH_KEY = "PORTFOLIO_LAST_SEARCH";
export const PORTFOLIO_LAST_SORT_KEY = "PORTFOLIO_LAST_SORT";
export const PORTFOLIO_COLUMN_STATE_KEY = "PORTFOLIO_COLUMN_STATE";
export const PORTFOLIO_TOTAL_REVENUE_DISPLAY =
  "PORTFOLIO_TOTAL_REVENUE_DISPLAY";
export const EXECUTIVE_TOTAL_REVENUE_DISPLAY =
  "EXECUTIVE_TOTAL_REVENUE_DISPLAY";
export const PORTFOLIO_SHOW_ALERT = "PORTFOLIO_SHOW_ALERT";
export const EXECUTIVE_SHOW_ALERT = "EXECUTIVE_SHOW_ALERT";
export const PORTFOLIO_SHOW_ALREADY = "PORTFOLIO_SHOW_ALREADY";
export const PORTFOLIO_TOTAL_REVENUE_DATA = "PORTFOLIO_TOTAL_REVENUE_DATA";
export const COLLECTION_COLUMN_STATE = "COLLECTION_COLUMN_STATE";
export const CLOUD_COLLECTION_COLUMN = [
  "invoiceArrMeetingMsgCloud",
  "invoiceArrCallingCloud",
  "invoiceArrContactCenterCloud",
  "invoiceArrDeviceCloud",
  "invoiceArrCPassCloud",
];
export const ONPREM_COLLECTION_COLUMN = [
  "invoiceArrMeetingMsgOnPrem",
  "invoiceArrCallingOnPrem",
  "invoiceArrContactCenterOnPrem",
  "invoiceArrDeviceOnPrem",
];

export const COLLAB_COLLECTION_COLUMN = [
  "invoiceArrMeetingMsgOnPrem",
  "invoiceArrCallingOnPrem",
  "invoiceArrContactCenterOnPrem",
  "invoiceArrDeviceOnPrem",
  "invoiceArrMeetingMsgCloud",
  "invoiceArrCallingCloud",
  "invoiceArrContactCenterCloud",
  "invoiceArrDeviceCloud",
  "invoiceArrCPassCloud",
];

export const VIDEO_DEVICE_AMOUNT_3Y = ["videoDeviceAmount3Y"];

export const NON_COLLAB_COLLECTION_COLUMN = [
  "invoiceArrNonCollabSecurity",
  "invoiceArrNonCollabService",
  "invoiceArrNonCollabOther",
];

export const BREAK_UP_CLOUD_CONTACT_CENTER_COLUMN = [
  "invoiceArrCloudWXCC",
  "invoiceArrCloudWXCCAddon",
  "invoiceArrCloudWXCCDC",
  "invoiceArrCloudWXCCE",
];

export const BREAK_UP_ARR_COLUMNS = [...BREAK_UP_CLOUD_CONTACT_CENTER_COLUMN];

export const SUB_GROUP_MAPPING = [
  {
    colId: "invoiceArrContactCenterCloud",
    groupHeader: "Invoice ARR (Cloud Contact Center)",
    children: BREAK_UP_CLOUD_CONTACT_CENTER_COLUMN,
  },
];

export const COLLECTION_COLUMN = [
  ...ONPREM_COLLECTION_COLUMN,
  ...CLOUD_COLLECTION_COLUMN.slice(0, -1),
  "invoiceArrCPassCloud",
  ...NON_COLLAB_COLLECTION_COLUMN,
  ...BREAK_UP_CLOUD_CONTACT_CENTER_COLUMN,
  ...VIDEO_DEVICE_AMOUNT_3Y,
];

export const SUIT_COLLECTION_COLUMN = [
  "invoiceArrSuiteMeetingCloud",
  // "invoiceArrSuiteMeetingOnPrem",
  "invoiceArrSuiteCallingCloud",
  "invoiceArrSuiteCallingOnPrem",
  // Exclude for the time being
  // "invoiceArrSuiteOther",
];

export const SUMMARY_CONTACT_LAST_SEARCH_KEY = "SUMMARY_CONTACT_LAST_SEARCH";
export const SUMMARY_CONTACT_LAST_SORT_KEY = "SUMMARY_CONTACT_LAST_SORT";
export const SUMMARY_CONTACT_COLUMN_STATE_KEY = "SUMMARY_CONTACT_COLUMN_STATE";
export const SUMMARY_NOTE_LAST_SEARCH_KEY = "SUMMARY_NOTE_LAST_SEARCH";
export const SUMMARY_NOTE_LAST_SORT_KEY = "SUMMARY_NOTE_LAST_SORT";
export const SUMMARY_NOTE_COLUMN_STATE_KEY = "SUMMARY_NOTE_COLUMN_STATE";
export const QVR_LAST_SEARCH_KEY = "QVR_LAST_SEARCH";
export const QVR_LAST_SORT_KEY = "QVR_LAST_SORT";
export const QVR_COLUMN_STATE_KEY = "QVR_COLUMN_STATE";
export const ACTIVITY_DETAIL_LAST_SEARCH_KEY =
  "ACTIVITY_DETAIL_LAST_SEARCH_KEY";
export const ACTIVITY_DETAIL_LAST_SORT_KEY = "ACTIVITY_DETAIL_LAST_SORT_KEY";
export const ACTIVITY_DETAIL_COLUMN_STATE_KEY =
  "ACTIVITY_DETAIL_COLUMN_STATE_KEY";
export const ACTIVITY_LAST_SEARCH_KEY = "ACTIVITY_LAST_SEARCH_KEY";
export const ACTIVITY_LAST_SORT_KEY = "ACTIVITY_LAST_SORT_KEY";
export const ACTIVITY_COLUMN_STATE_KEY = "ACTIVITY_COLUMN_STATE_KEY";
export const MY_TASK_LAST_SEARCH_KEY = "MY_TASK_LAST_SEARCH_KEY";
export const MY_TASK_LAST_SORT_KEY = "MY_TASK_LAST_SORT_KEY";
export const MY_TASK_COLUMN_STATE_KEY = "MY_TASK_COLUMN_STATE_KEY";
export const PLAYBOOK_LAST_SEARCH_KEY = "PLAYBOOK_LAST_SEARCH_KEY";
export const PLAYBOOK_LAST_SORT_KEY = "PLAYBOOK_LAST_SORT_KEY";
export const PLAYBOOK_COLUMN_STATE_KEY = "PLAYBOOK_COLUMN_STATE_KEY";
export const TRANSACTION_LAST_SEARCH_KEY = "TRANSACTION_LAST_SEARCH";
export const TRANSACTION_LAST_SORT_KEY = "TRANSACTION_LAST_SORT";
export const TRANSACTION_COLUMN_STATE_KEY = "TRANSACTION_COLUMN_STATE";
export const HOST_USAGE_LAST_SEARCH_KEY = "HOST_USAGE_LAST_SEARCH";
export const HOST_USAGE_LAST_SORT_KEY = "HOST_USAGE_LAST_SORT";
export const HOST_USAGE_COLUMN_STATE_KEY = "HOST_USAGE_COLUMN_STATE";
export const TECH_SUPPORT_INFO_LAST_SEARCH_KEY =
  "TECH_SUPPORT_INFO_LAST_SEARCH";
export const TECH_SUPPORT_INFO_SORT_KEY = "TECH_SUPPORT_INFO_LAST_SORT";
export const TECH_SUPPORT_INFO_COLUMN_STATE_KEY =
  "TECH_SUPPORT_INFO_COLUMN_STATE";
export const TRANSACTION_DETAIL_COLUMN_STATE_KEY =
  "TRANSACTION_DETAIL_COLUMN_STATE";
export const DASHBOARD_ENTERPRISE_LAST_SEARCH_KEY =
  "DASHBOARD_ENTERPRISE_LAST_SEARCH";
export const DASHBOARD_ENTERPRISE_LAST_SORT_KEY =
  "DASHBOARD_ENTERPRISE_LAST_SORT";
export const DASHBOARD_ENTERPRISE_STATE_KEY = "DASHBOARD_ENTERPRISE_STATE_KEY";
export const DASHBOARD_ENTERPRISE_MENUTAB_KEY =
  "DASHBOARD_ENTERPRISE_MENUTAB_KEY";
export const WHATS_NEW_VERSION_KEY = "WHATS_NEW_VERSION";
export const WHATS_NEW_SHOW_ME_LATER_KEY = "WHATS_NEW_SHOW_ME_LATER";

export const MY_TASK_BANNER_KEY = "MY_TASK_BANNER_KEY";
export const TOKEN_COOKIE = "accessTokenCookie";
export const EMAILID_COOKIE = "emailIdCookie";
export const CI_EMAILID_COOKIE = "CIEmailIdCookie";
export const MOCK_USER_COOKIE = "mockUserEmailIdCookie";
export const UUID_COOKIE = "uuidCookie";
export const FIRST_NAME_COOKIE = "userNameCookie";
export const CI_FIRST_NAME_COOKIE = "CIUserNameCookie";
export const LAST_NAME_COOKIE = "lastNameCookie";
export const LAST_URL_COOKIE = "lastUrlCookie";
export const ROLE_COOKIE = "roleCookie";
export const DASHBOARD_FORCAST_LAST_SEARCH_KEY =
  "DASHBOARD_FORCAST_LAST_SEARCH";
export const DASHBOARD_FORCAST_LAST_SORT_KEY = "DASHBOARD_FORCAST_LAST_SORT";
export const DASHBOARD_FORCAST_STATE_KEY = "DASHBOARD_FORCAST_STATE_KEY";
export const DASHBOARD_SUMMARY_COUNT_DATA_DISPLAY =
  "DASHBOARD_SUMMARY_COUNT_DATA_DISPLAY";
export const DASHBOARD_TOP_ACCOUNTS_LAST_SEARCH_KEY =
  "DASHBOARD_TOP_ACCOUNTS_LAST_SEARCH";
export const DASHBOARD_TOP_ACCOUNTS_LAST_SORT_KEY =
  "DASHBOARD_TOP_ACCOUNTS_LAST_SORT";
export const DASHBOARD_TOP_ACCOUNTS_STATE_KEY =
  "DASHBOARD_TOP_ACCOUNTS_STATE_KEY";
export const DASHBOARD_BENCHMARKING_STATE_KEY =
  "DASHBOARD_BENCHMARKING_STATE_KEY";
export const DASHBOARD_BENCHMARKING_LAST_SORT_KEY =
  "DASHBOARD_BENCHMARKING_LAST_SORT_KEY";
export const DASHBOARD_BENCHMARKING_LAST_SEARCH_KEY =
  "DASHBOARD_BENCHMARKING_LAST_SEARCH_KEY";
// for executive health dashboard
export const DASHBOARD_EXECUTIVE_STATE_KEY = "DASHBOARD_EXECUTIVE_STATE_KEY";
export const DASHBOARD_EXECUTIVE_LAST_SORT_KEY =
  "DASHBOARD_EXECUTIVE_LAST_SORT_KEY";
export const DASHBOARD_EXECUTIVE_LAST_SEARCH_KEY =
  "DASHBOARD_EXECUTIVE_LAST_SEARCH_KEY";

export const HOME_PAGE_JUMP = "HOME_PAGE_JUMP";

export const HOME_SELECTED_DASHBOARD = "HOME_SELECTED_DASHBOARD";
export const HOME_LAST_FILTER = "HOME_LAST_FILTER";

export const MEETING_USAGE_SELECTION = "MEETING_USAGE_SELECTION";
export const TEAM_USAGE_SELECTION = "TEAM_USAGE_SELECTION";
export const CALLING_USAGE_SELECTION = "CALLING_USAGE_SELECTION";

export const DISCLAIMER_LIST = [
  "The usage data trend in this report is an approximate figure only and may differ slightly from data depictions in other systems. Typically, metrics shown are up until 4 days prior to current day. Each chart depicts the latest date for which data is available.",
  "The single True Forward source of truth for the Collaboration Flex Active User (A-FLEX) and Spark Active User (A-SPK-ACTIVE) is Cisco Commerce (CCW) -> Cloud/SaaS Subscriptions (aka Subscription UI) -> ‘View Consumption Details’ (aka Consumption View) for each subscription.",
  "Cisco WebEx Control Hub is the ONLY data source related to the True Forward that aligns to the logic described in the Active User Offer Description. Consumption View sources data directly from Cisco WebEx Control Hub for the True Forward.",
  "All other data points obtained outside of Consumption View and Control Hub are NOT True Forward applicable, do NOT align to the Active User Offer Description, and will NOT be used in the automation of the Flex and Spark True Forwards.",
  "Not included is the usage of those Webex Sites that are in cluster 'F'.",
];

export const TECH_SUPPORT_TYPE = [
  // "Summary",
  // "Incident Categorization by Product",
  "Include Ticket Details",
] as const;

export const TECH_SUPPORT_SELECTION = "TECH_SUPPORT_SELECTION";

export const RANK_VALUE_LIST = [
  {
    color: "RED",
    risk: "Significant Risk",
  },
  { color: "GREEN", risk: "No Risk" },
  { color: "ORANGE", risk: "Moderate Risk" },
  { color: "YELLOW", risk: "Minimal Risk" },
];

export const SENT_EMAIL_LAST_SEARCH_KEY = "SENT_EMAIL_LAST_SEARCH";
export const SENT_EMAIL_LAST_SORT_KEY = "SENT_EMAIL_LAST_SORT";
export const SENT_EMAIL_COLUMN_STATE_KEY = "SENT_EMAIL_COLUMN_STATE";
export const SENT_EMAIL_TWO_TILES_LAST_SEARCH_KEY =
  "SENT_EMAIL_TWO_TILES_LAST_SEARCH";
export const SENT_EMAIL_TWO_TILES_LAST_SORT_KEY =
  "SENT_EMAIL_TWO_TILES_LAST_SORT";
export const SENT_EMAIL_TWO_TILES_COLUMN_STATE_KEY =
  "SENT_EMAIL_TWO_TILES_COLUMN_STATE";
export const SELECT_CONTACT_LAST_SEARCH_KEY = "SELECT_CONTACT_LAST_SEARCH_KEY";
export const SELECT_CONTACT_LAST_SORT_KEY = "SELECT_CONTACT_LAST_SORT_KEY";
export const SELECT_CONTACT_COLUMN_STATE_KEY =
  "SELECT_CONTACT_COLUMN_STATE_KEY";

export const ACCEPT_STATUS = "ACCEPT_STATUS";

export const EMAIL_BANNER_KEY = "EMAIL_BANNER_KEY";

export const colorStyle: { [key: string]: string } = {
  ["PENDING"]: "#FC9D03",
  ["SENT"]: "#005E7D",
  ["FAILED"]: "#D4371C",
  ["IN_PROGRESS"]: "#00AB50",
};

export const categoryColorStyle: any = {
  ["Introduction"]: {
    bgColor: "#E6F9FA",
    color: "#008094",
  },
  ["Renewal"]: {
    bgColor: "#FFF4ED",
    color: "#F26B1D",
  },
  ["Other"]: {
    bgColor: "#F2F4FF",
    color: "#393A47",
  },
  ["Usage"]: {
    bgColor: "#F3F7E9",
    color: "#588219",
  },
  ["default"]: {
    bgColor: "#F5F7F6",
    color: "#5D756B",
  },
};

export const MEETING_SITEURL_LIST = [
  "unistuttgart.webex.com",
  "unistuttgart-events.webex.com",
  "arena2036.webex.com",
  "lrk-bw.webex.com",
  "unistuttgart-beta.webex.com",
  "unistuttgart-gamma.webex.com",
  "unistuttgart-preview.webex.com",
  "uni-stuttgart.webex.com",
  "unistuttgart-bts.webex.com",
  "unistuttgart-obsoleted.webex.com",
];

export const SUMMARY_OTHER_ACCOUNTS_COLUMN_STATE_KEY =
  "SUMMARY_OTHER_ACCOUNTS_COLUMN_STATE_KEY";

export const SUMMARY_CAV_ACCOUNTS_COLUMN_STATE_KEY =
  "SUMMARY_CAV_ACCOUNTS_COLUMN_STATE_KEY";

export const SUMMARY_ALERT_LAST_SEARCH_KEY = "SUMMARY_ALERT_LAST_SEARCH";
export const SUMMARY_ALERT_LAST_SORT_KEY = "SUMMARY_ALERT_LAST_SORT";
export const SUMMARY_ALERT_COLUMN_STATE_KEY = "SUMMARY_ALERT_COLUMN_STATE";

export const SUMMARY_ALERT_SORT_KEY_MAPPING = {
  category: "alertType",
};

export const SUMMARY_ALERT_FILTER_KEY_MAPPING = {
  type: "alertType",
  externalAlertColumn: "alertSubType",
};

export const ACTIVITY_SESSION_UPDATE_KEY = "ACTIVITY_SESSION_UPDATE_KEY";

export const PII_PLACEHOLDER = "Data Restriction";
export const PII_BANNER =
  "Data Restriction: This report may contain some contacts based in EU region and because of privacy compliance reasons it does not include their Email/First Name/Last Name.";
// export const NOTELIST_INCLUDE_ALERT = "NOTELIST_INCLUDE_ALERT";

export const PRE_SELECTED_CONTACTS = "PRE_SELECTED_CONTACTS";
export const DISMISSED_BANNER_KEY = "DISMISSED_BANNER_KEY";

export const ACTIVITY_STATUS_CONST = {
  BLOCKED: "Blocked",
  NOT_STARTED: "Not Started",
  IN_PROGRESS: "In Progress",
  COMPLETED: "Completed",
  NO_ACTION: "No Action",
} as const;

export type ActivityStatus = typeof ACTIVITY_STATUS_CONST[keyof typeof ACTIVITY_STATUS_CONST];

export const ACTIVITY_STATUS_ORDER = Object.values(ACTIVITY_STATUS_CONST);

export const ACTIVITY_STATUS = Object.values(
  ACTIVITY_STATUS_CONST
).map((x) => ({ value: x, name: x }));

export const colorStyleList: any = {
  "Not Started": {
    bgColor: "#D2EDF6",
    color: "#007AA3",
  },
  "In Progress": {
    bgColor: "#FFF0D9",
    color: "#FC9D03",
  },
  Completed: {
    bgColor: "#D9F2E5",
    color: "#00AB50",
  },
  "No Action": {
    bgColor: "#EDEDED",
    color: "#898989",
  },
  Blocked: {
    bgColor: "#FEE8E4",
    color: "#D4371C",
  },
  Active: {
    bgColor: "transparent",
    color: "#00AB50",
  },
  Inactive: {
    bgColor: "transparent",
    color: "#FF5F58",
  },
  "Pending Approval": {
    bgColor: "transparent",
    color: "#FC9D03",
  },
  Rejected: {
    bgColor: "transparent",
    color: "#D4371C",
  },
  default: {
    bgColor: "#EDEDED",
    color: "#898989",
  },
};

export const PLATFORM = {
  SP: "Success Portal",
  DM: "Digital Marketing Platform",
} as const;

export const PORTFOLIO_GROUP_EXPAND = "PORTFOLIO_GROUP_EXPAND";
export const EXECUTIVE_GROUP_EXPAND = "EXECUTIVE_GROUP_EXPAND";
export const SENT_EMAIL_NEW_COLUMNS = "SENT_EMAIL_NEW_COLUMNS";

export const SUMMARY_DEVICE_LAST_SEARCH_KEY = "SUMMARY_DEVICE_LAST_SEARCH_KEY";
export const SUMMARY_DEVICE_LAST_SORT_KEY = "SUMMARY_DEVICE_LAST_SORT_KEY";
export const SUMMARY_DEVICE_COLUMN_STATE_KEY =
  "SUMMARY_DEVICE_COLUMN_STATE_KEY";

export const SUCCESS_PLAN_LATEST_VERSION = "SUCCESS_PLAN_LATEST_VERSION";
export const PRE_SELECTED_ACCOUNT = "PRE_SELECTED_ACCOUNT";
export const FINAL_SELECTED_ACCOUNT = "FINAL_SELECTED_ACCOUNT";
export const CURRENT_ACCOUNTKEY = "CURRENT_ACCOUNTKEY";

export const SUBSCRIPTION_FORCASTING_MAPPING = {
  Downsell: 1,
  Upsell: 2,
  "Renewal At Same": 3,
  Cancel: 4,
  "Managed Churn": 5,
  "Outside Forecasting Window": 6,
  "Renewal At Risk": 7,
};
